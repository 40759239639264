// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz@0,6..96;1,6..96&family=Noto+Serif+JP&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,700;1,6..96,700&family=Noto+Serif+JP:wght@600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-18c2bfa6]:root {
  font-size: clamp(13px + 2 * (100vw - 1440px) / 480, 1vw, 16px + 2 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-18c2bfa6]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1119px) {
[data-v-18c2bfa6]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-18c2bfa6]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
.main[data-v-18c2bfa6] {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 6.4rem 0 0;
}
@media screen and (max-width: 769px) {
.main[data-v-18c2bfa6] {
    padding: 0 4.8rem 0 0;
}
}
.pic[data-v-18c2bfa6] {
  position: relative;
  padding: 37.5% 0 0;
  border-radius: 0 0 10vw 0;
  overflow: hidden;
  min-height: 32rem;
}
@media screen and (max-width: 769px) {
.pic[data-v-18c2bfa6] {
    min-height: 20rem;
}
}
.pic[data-v-18c2bfa6]:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #2f2c2c;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.24;
  z-index: 2;
}
.pic img[data-v-18c2bfa6] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.04);
  animation: zoomOutPicture-18c2bfa6 1000ms cubic-bezier(0.65, 0, 0.35, 1) forwards;
}
@keyframes zoomOutPicture-18c2bfa6 {
0% {
    transform: scale(1.04);
}
100% {
    transform: scale(1);
}
}
.pic div[data-v-18c2bfa6] {
  position: absolute;
  bottom: 2.4rem;
  left: calc(calc(var(--size) + 2vw) * 1.5);
  z-index: 2;
  color: #fefcfc;
  line-height: 1.5;
  text-transform: uppercase;
}
@media screen and (max-width: 769px) {
.pic div[data-v-18c2bfa6] {
    bottom: 1.6rem;
}
}
.pic div[data-v-18c2bfa6] > * {
  opacity: 0;
}
.pic div p[data-v-18c2bfa6] {
  font-size: 1rem;
  font-weight: 400;
  margin: 0 0 0.4rem 0.32rem;
  letter-spacing: 0.24vmin;
  animation: upToTitle-18c2bfa6 1000ms cubic-bezier(0.65, 0, 0.35, 1) forwards;
}
@media screen and (max-width: 769px) {
.pic div p[data-v-18c2bfa6] {
    font-size: 0.88rem;
}
}
.pic div h1[data-v-18c2bfa6] {
  font-size: 3.72rem;
  font-weight: 400;
  letter-spacing: 0;
  animation: upToTitle-18c2bfa6 1000ms 200ms cubic-bezier(0.65, 0, 0.35, 1) forwards;
}
@media screen and (max-width: 769px) {
.pic div h1[data-v-18c2bfa6] {
    font-size: 1.72rem;
}
}
@keyframes upToTitle-18c2bfa6 {
0% {
    opacity: 0;
    transform: translateY(1.2rem);
}
100% {
    opacity: 1;
    transform: scale(1);
}
}
.bread[data-v-18c2bfa6] {
  position: absolute;
  top: 8rem;
  right: 3.8rem;
  z-index: 10;
}
@media screen and (max-width: 769px) {
.bread[data-v-18c2bfa6] {
    top: 6.4rem;
    right: 2.8rem;
}
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./hero.vue","webpack://./src/js/components/hero.vue"],"names":[],"mappings":"AAGA;6DAAA;AAeA;6DAAA;AAOA;6DAAA;AAEA;EACC,2FAAA;EAKA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;ACtBF;ADuBC;AAZD;IAaE,eAAA;ACpBA;AACF;ADqBC;AAfD;IAgBE,eAAA;IACE,YAAA;AClBF;AACF;ADmBC;AAnBD;IAoBE,iBAAA;IACE,YAAA;AChBF;AACF;ADmHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;AChHD;AC9DA;EACE,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,qBAAA;ADgEF;AC/DE;AALF;IAMI,qBAAA;ADkEF;AACF;AChEA;EACE,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,iBAAA;ADmEF;AClEE;AANF;IAOI,iBAAA;ADqEF;AACF;ACpEE;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,mBFnBK;EEoBL,kBAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,UAAA;ADsEJ;ACpEE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,iFAAA;ADsEJ;ACrEI;AACE;IACE,sBAAA;ADuEN;ACrEI;IACE,mBAAA;ADuEN;AACF;ACpEE;EACE,kBAAA;EACA,cAAA;EACA,yCAAA;EACA,UAAA;EACA,cF9CI;EE+CJ,gBAAA;EACA,yBAAA;ADsEJ;ACrEI;AARF;IASI,cAAA;ADwEJ;AACF;ACvEI;EACE,UAAA;ADyEN;ACvEI;EACE,eAAA;EACA,gBAAA;EACA,0BAAA;EACA,wBAAA;EACA,4EAAA;ADyEN;ACxEM;AANF;IAOI,kBAAA;AD2EN;AACF;ACzEI;EACE,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kFAAA;AD2EN;AC1EM;AALF;IAMI,kBAAA;AD6EN;AACF;AC3EI;AACE;IACE,UAAA;IACA,6BAAA;AD6EN;AC3EI;IACE,UAAA;IACA,mBAAA;AD6EN;AACF;ACxEA;EACE,kBAAA;EACA,SAAA;EACA,aAAA;EACA,WAAA;AD2EF;AC1EE;AALF;IAMI,WAAA;IACA,aAAA;AD6EF;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
