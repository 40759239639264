<template>
  <div class="main">
    <div class="pic" v-if="pic">
      <div>
        <p v-html="eng"></p>
        <h1 v-html="title"></h1>
      </div>
      <img :src="pic" :alt="title">
    </div>
    <div class="bread">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main{
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 6.4rem 0 0;
  @media screen and (max-width: $phone){
    padding: 0 4.8rem 0 0;
  }
}
.pic{
  position: relative;
  padding: calc(100% / 16 * 6) 0 0;
  border-radius: 0 0 10vw 0;
  overflow: hidden;
  min-height: 32rem;
  @media screen and (max-width: $phone){
    min-height: 20rem;
  }
  &:before{
    content: '';
    width: 100%;
    height: 100%;
    background: $normal;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .24;
    z-index: 2;
  }
  img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.04);
    animation: zoomOutPicture 1000ms $ease forwards;
    @keyframes zoomOutPicture {
      0%{
        transform: scale(1.04);
      }
      100%{
        transform: scale(1);
      }
    }
  }
  div{
    position: absolute;
    bottom: 2.4rem;
    left: side2x();
    z-index: 2;
    color: $white;
    line-height: 1.5;
    text-transform: uppercase;
    @media screen and (max-width: $phone){
      bottom: 1.6rem;
    }
    > * {
      opacity: 0;
    }
    p{
      font-size: 1rem;
      font-weight: 400;
      margin: 0 0 .4rem .32rem;
      letter-spacing: .24vmin;
      animation: upToTitle 1000ms $ease forwards;
      @media screen and (max-width: $phone){
        font-size: .88rem;
      }
    }
    h1{
      font-size: 3.72rem;
      font-weight: 400;
      letter-spacing: 0;
      animation: upToTitle 1000ms 200ms $ease forwards;
      @media screen and (max-width: $phone){
        font-size: 1.72rem;
      }
    }
    @keyframes upToTitle {
      0%{
        opacity: 0;
        transform: translateY(1.2rem);
      }
      100%{
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

.bread{
  position: absolute;
  top: 8rem;
  right: 3.8rem;
  z-index: 10;
  @media screen and (max-width: $phone){
    top: 6.4rem;
    right: 2.8rem;
  }
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false
    },
    eng: {
      type: String,
      required: false
    },
    pic: {
      type: String,
      required: false
    },
  }
}
</script>
