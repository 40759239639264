import Swiper from 'swiper'
import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as SmoothScroll from 'smooth-scroll'
export default function index(){

  const fv = document.getElementById('firstview')
  if(fv){
    gsap.fromTo(fv,
      {},
      {
        background: '#541212',
        scrollTrigger: {
          trigger: fv,
          start: "top top",
          end: "+=100%",
          scrub: 0,
          once: false,
        }
      }
    )

    gsap.to(".firstview .fadeto", {
      x: (i) => {
        if (i === 0) return ".85vw"
        if (i === 1) return "-.85vw"
        return "0vw"
      },
      y: '10dvh',
      opacity: 0,
      scrollTrigger: {
        trigger: fv,
        start: "top top",
        end: "+=50%",
        scrub: 1,
        once: false,
      }
    })

    gsap.to('.firstview__inner', {
      scrollTrigger: {
        trigger: fv,
        pin: '.firstview__inner',
        start: 'top top',
        end: 'bottom bottom',
        scrub: true,
        //markers: true
      }
    })

    const fvFrame = getComputedStyle(fv).getPropertyValue('--fvframe').trim();

    gsap.fromTo('.firstview__inner',
      {
        border: '0 rgba(0,0,0,0) solid',
      },
      {
        border: `${fvFrame} rgba(0,0,0,0) solid`,
        pointerEvents: 'none',
        opacity: 0,
        scrollTrigger: {
          trigger: fv,
          start: "top top",
          end: "bottom bottom",  
          scrub: 0,
          once: false,
        }
      }
    )

    gsap.fromTo('.firstview__next__inner__pic',
      {

      },
      {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        scrollTrigger: {
          trigger: '.firstview__next__inner__pic',
          start: "top bottom",
          end: "top center-=25%",  
          scrub: 3,
          once: false,
          //markers: true,
        }
      }
    )
  }


  const toRightAnimation = document.getElementById('toRightAnimation')
  const toRightAnimationSlides = document.getElementById('toRightAnimationSlides')
  if (toRightAnimation && toRightAnimationSlides && window.innerWidth >= 1120){ 

    const toRightAnimationSlidesPin = document.getElementById('toRightAnimationSlidesPin')
    const slideChild = document.querySelectorAll('.second__slides__slide')
    const progressBar = document.querySelector('.second__pin__progress__bar')

    const slideCount = (slideChild.length - 1) * -100 + '%'
    const endSlidepoint = "+="+slideChild.length * 300+"%"
    const endPinPoint = "+="+slideChild.length * 350+"%"

    ScrollTrigger.create({
      trigger: toRightAnimation,
      pin: toRightAnimation,
      start: "top top",
      end: endPinPoint,
      invalidateOnRefresh: true,
      //markers: true,
    })

    ScrollTrigger.create({
      trigger: toRightAnimation,
      pin: toRightAnimationSlidesPin,
      start: "top top",
      end: endPinPoint,
      invalidateOnRefresh: true,
      pinSpacing: false,
      //markers: true,
    })

    gsap.fromTo(toRightAnimationSlides,
      {},
      {
        left: slideCount,
        scrollTrigger: {
          trigger: '.slides-section-sapcer',
          start: "top top-=50%",
          end: endSlidepoint,
          ease: "power1.in",
          scrub: 2,
          once: false,
          invalidateOnRefresh: true,
          //markers: true,
        }
      }
    )

    gsap.fromTo(progressBar,
      {},
      {
        width: '100%',
        scrollTrigger: {
          trigger: toRightAnimation,
          start: "top top",
          end: endSlidepoint,
          scrub: 1,
          once: false,
          invalidateOnRefresh: true,
          //markers: true,
        }
      }
    )

    gsap.utils.toArray(".toAnimeteParallax").forEach((el, index) => {
      gsap.fromTo(el,
        {},
        {
          y: '0',
          scrollTrigger: {
            trigger: el,
            start: "top bottom",
            end: "bottom top",
            scrub: 2,
            once: false,
            invalidateOnRefresh: true,
            //markers: true,
          }
        }
      )
    })

    gsap.utils.toArray(".site-index .third").forEach((el, index) => {
      gsap.fromTo('.second__pin__effect',
        {
        },
        {
          opacity: 1,
          scrollTrigger: {
            trigger: el,
            start: "top center",
            end: "top top",
            scrub: 1,
            once: false,
            invalidateOnRefresh: true,
            //markers: true,
          }
        }
      )
    })
  }
  
  gsap.utils.toArray(".site-index .third__grid__item__pic").forEach((el, index) => {
    gsap.fromTo(el,
      {},
      {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        scrollTrigger: {
          trigger: '.site-index .third',
          start: "top bottom",
          end: "top top+=25%",
          scrub: 3,
          once: false,
          invalidateOnRefresh: true,
          //markers: true,
        }
      }
    )
    gsap.fromTo(el.children[0],
      {},
      {
        scale: 1,
        scrollTrigger: {
          trigger: '.site-index .third',
          start: "top bottom",
          end: "center top",
          scrub: 1,
          once: false,
          invalidateOnRefresh: true,
          //markers: true,
        }
      }
    )
  })

  gsap.utils.toArray(".second__cover").forEach((el, index) => {
    gsap.fromTo(el,
      {},
      {
        height: '0',
        scrollTrigger: {
          trigger: toRightAnimation,
          start: "top bottom",
          end: "top top",
          scrub: 1,
          once: false,
          invalidateOnRefresh: true,
          //markers: true,
        }
      }
    )
  })

  const fixgrid = document.getElementById('fixgrid')
  const fixgridbox = document.getElementById('fixgridbox')
  if(fixgrid && fixgridbox){
    ScrollTrigger.create({
      trigger: fixgrid,
      pin: fixgridbox,
      start: "top top",
      end: "+=300%",
      invalidateOnRefresh: true,
      pinSpacing: true,
      //markers: true,
    })

    gsap.to(".gridbox__item.main", {
      scale: 3.3,
      scrollTrigger: {
        trigger: fixgrid,
        start: "top top",
        end: "+=200%",
        scrub: 2,
        //markers: true,
      }
    });

    ScrollTrigger.create({
      trigger: fixgrid,
      start: "center center-=25%",
      end: "+=200%",
      toggleClass: { targets: '.gridover', className: "active" },
      once: false,
      //markers: true,
    });
  
    gsap.to(".gridbox__item:not(.main)", {
      x: (i) => {
        if (i === 0 || i === 3 || i === 5) return "-50vw"
        if (i === 2 || i === 4 || i === 7) return "50vw"
        return "0vw"
      },
      y: (i) => {
        if (i === 0 || i === 1 || i === 2) return "-50vh"
        if (i === 5 || i === 6 || i === 7) return "50vh"
        return "0vh"
      },
      scaleX: (i) => {
        if (i === 0 || i === 1 || i === 2) return 2.5
        if (i === 5 || i === 6 || i === 7) return 2.5
        return 1
      },
      scaleY: (i) => {
        if (i === 3 || i === 4) return 3.3
        return 1
      },
      scrollTrigger: {
        trigger: fixgrid,
        start: "top top",
        end: "+=200%",
        scrub: 2
      }
    })

    gsap.to(".gridbox__item:not(.main) img", {
      scaleY: (i) => {
        if (i === 0 || i === 1 || i === 2) return 2.5
        if (i === 5 || i === 6 || i === 7) return 2.5
        return 1
      },
      scaleX: (i) => {
        if (i === 3 || i === 4) return 3.3
        return 1
      },
      scrollTrigger: {
        trigger: fixgrid,
        start: "top top",
        end: "+=200%",
        scrub: 2
      }
    })
  }

}