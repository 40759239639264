<template>
  <header id="site-header">
    <figure class="logo">
      <a href="/">
        <img src="/images/common/logo.webp" alt="ろくや 網元の宿" />
      </a>
    </figure>
    <div class="trg" @click="trg = !trg" :class="{'active': trg}">
      <i></i>
      <i></i>
      <i></i>
    </div>
    <a href="https://reserve.489ban.net/client/rokuya-resort/0/plan/availability/daily" target="_blank" rel="nofollow" class="other" :class="{'active': trg}">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M603-132v-28h125q12 0 22-10t10-22v-336H200v154h-28v-314q0-26 17-43t43-17h80v-92h32v92h276v-92h28v92h80q26 0 43 17t17 43v496q0 26-17 43t-43 17H603ZM320-37l-19-19 128-130H66v-28h363L301-344l19-19 163 163L320-37ZM200-556h560v-132q0-12-10-22t-22-10H232q-12 0-22 10t-10 22v132Zm0 0v-164 164Z"/></svg>
        ご予約はこちら
      </div>
    </a>
    <Transition name="nav">
      <div class="fix" v-if="trg">
        <nav class="gnavi" role="navigation" itemscope="itemscope" itemtype="http://scheme.org/SiteNavigationElement">
          <figure class="logo">
            <a href="/">
              <img src="/images/common/logo.webp" alt="ろくや 網元の宿" />
            </a>
          </figure>
          <div class="pics" :class="{'active':trg}">
            <figure><img src="/images/common/menu_pic_01.webp" alt="写真"></figure>
            <figure><img src="/images/common/menu_pic_02.webp" alt="写真"></figure>
          </div>
          <div class="menu">
            <ul :class="{'active':trg}">
              <li itemprop="name"><a itemprop="url" href="/aboutus/" data-eng="Rokuya">ろくやについて</a></li>
              <li itemprop="name"><a itemprop="url" href="/plan/" data-eng="Plan">宿泊プラン</a></li>
              <li itemprop="name"><a itemprop="url" href="/material/" data-eng="ingredients">厳選素材</a></li>
              <li itemprop="name"><a itemprop="url" href="/room/" data-eng="Room">お部屋</a></li>
              <li itemprop="name"><a itemprop="url" href="/spa/" data-eng="Hot spring">温泉</a></li>
              <li itemprop="name"><a itemprop="url" href="/access/" data-eng="Access">交通・観光</a></li>
              <li itemprop="name"><a itemprop="url" href="/faq/" data-eng="Q&amp;A">よくある質問</a></li>
              <li itemprop="name"><a data-eng="Reservation" href="https://reserve.489ban.net/client/rokuya-resort/0/plan/availability/daily" target="_blank" rel="nofollow">ご予約<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M224.62-160q-27.62 0-46.12-18.5Q160-197 160-224.62v-510.76q0-27.62 18.5-46.12Q197-800 224.62-800h224.61v40H224.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v510.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h510.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-224.61h40v224.61q0 27.62-18.5 46.12Q763-160 735.38-160H224.62Zm164.92-201.23-28.31-28.31L731.69-760H560v-40h240v240h-40v-171.69L389.54-361.23Z"></path></svg></a></li>
            </ul>
            <dl class="meta">
              <dt>南房総岩井湯元温泉 網元の宿 ろくや<br>〒299-2216 千葉県南房総市久枝 494</dt>
              <dd><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M304.62-80Q277-80 258.5-98.5 240-117 240-144.62v-670.76q0-27.62 18.5-46.12Q277-880 304.62-880h350.76q27.62 0 46.12 18.5Q720-843 720-815.38v670.76q0 27.62-18.5 46.12Q683-80 655.38-80H304.62ZM480-158.46q12.38 0 21.58-9.19 9.19-9.2 9.19-21.58 0-12.39-9.19-21.58Q492.38-220 480-220q-12.38 0-21.58 9.19-9.19 9.19-9.19 21.58 0 12.38 9.19 21.58 9.2 9.19 21.58 9.19Zm-200-140h400V-740H280v441.54Z"/></svg>0470-57-2011</dd>
            </dl>
          </div>
        </nav>
      </div>
    </Transition>
  </header>
</template>

     

<style lang="scss" scoped>
$spSize: 4.8rem;
.nav-enter-active,
.nav-leave-active {
  transition: opacity 0.5s $ease;
}
.nav-enter-from,
.nav-leave-to {
  opacity: 0;
}
header{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 1000;
  .logo{
    width: 10rem;
    justify-self: flex-start;
    @media screen and (max-width: $phone){
      width: $spSize;
    }
    img{
      width: 100%;
    }
  }
  .other{
    position: fixed;
    top: 0;
    right: calc(6.4rem - 1px);
    height: 6.4rem;
    background: darken($base, 4%);
    color: $gray;
    line-height: 1.5;
    @include flex();
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    z-index: 900;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: clip-path 800ms $ease, background 400ms $ease;
    @media screen and (max-width: $phone){
      right: calc(#{$spSize} - 1px);
      height: $spSize;
      padding: 0 1.6rem;
    }
    &.active{
      clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 0);
    }
    &:hover{
      background: $sub;
    }
    div{
      font-size: .88rem;
      text-align: center;
      @media screen and (max-width: $phone){
        font-size: .76rem;
      }
      svg{
        width: 2.4rem;
        fill: $gray;
        display: block;
        margin: 0 auto .64rem;
        @media screen and (max-width: $phone){
          width: 1.8rem;
          margin-bottom: .4rem;
        }
      }
    }
  }
  .trg{
    position: fixed;
    top: 0;
    right: 0;
    width: 6.4rem;
    height: 6.4rem;
    cursor: pointer;
    overflow: hidden;
    z-index: 1000;
    @media screen and (max-width: $phone){
      width: $spSize;
      height: $spSize;
    }
    &:before,
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $base;
      z-index: -1;
    }
    &:after{
      width: 0;
      left: auto;
      right: 0;
      background: darken($normal, 20%);
      transition: all 320ms $ease;
    }
    &:hover{
      &:after{
        width: 100%;
        left: 0;
        right: auto;
      }
    }
    &.active{
      &:after{
        width: 100%;
      }
      &:hover{
        &:after{
          background: $normal;
        }
      }
      i{
        width: 0;
        &:first-of-type,
        &:last-of-type{
          width: 40%;
          top: 50%;
        }
        &:first-of-type{
          transform: translate(-50%) rotate(45deg);
        }
        &:last-of-type{
          transform: translate(-50%) rotate(-45deg);
        }
      }
    }
    i{
      width: 33%;
      height: 1px;
      background: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      transition: all 320ms $ease;
      &:first-of-type{
        top: calc(50% - .5rem);
      }
      &:last-of-type{
        top: calc(50% + .5rem);
      }
    }
  }
  .fix{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $base;
  }
  nav{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    @include flex();
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $phone){
      overflow: auto;
      display: grid;
      grid-template-columns: 1fr;
      > *{
        align-self: flex-start;
      }
    }
    .logo{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
    .pics{
      width: 44%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      @media screen and (max-width: $tab){
        width: 100%;
        height: 30%;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
      }
      @media screen and (max-width: $phone){
        height: 25dvh;
        min-height: 16rem;
        position: relative;
        order: 1;
        grid-template-rows: repeat(2, 1fr);
        grid-template-rows: auto;
      }
      figure{
        position: relative;
        overflow: hidden;
        &:before{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: $base;
          z-index: 2;
        }
        &:nth-of-type(odd){
          &:before{
            left: auto;
            right: 0;
          }
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          position: relative;
        }
      }
      &.active{
        figure{
          &:before{
            animation: menuPicBefore 800ms 200ms $ease forwards;
          }
          img{
            animation: menuPic 1200ms $ease forwards;
          }
          @keyframes menuPicBefore {
            0%{
              width: 100%;
            }
            100%{
              width: 0;
            }
          }
          @keyframes menuPic {
            0%{
              transform: scale(1.1);
            }
            100%{
              transform: scale(1);
            }
          }
        }
      }
    }
    .menu{
      width: 56%;
      height: 100%;
      padding: 0 0 5.6rem;
      position: absolute;
      background: $base;
      right: 0;
      top: 0;
      @include flex();
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $tab){
        width: 100%;
        height: 70%;
        top: auto;
        bottom: 0;
      }
      @media screen and (max-width: $phone){
        display: grid;
        grid-template-rows: 2fr;
        position: relative;
        height: auto;
        bottom: auto;
        top: 0;
        order: 2;
        padding: 0;
        min-height: 75dvh;
        align-self: center;
      }
      ul{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: side2x();
        grid-row-gap: 2rem;
        @media screen and (max-width: $tab){
          grid-column-gap: side();
        }
        @media screen and (max-width: $phone){
          grid-template-columns: repeat(2, 1fr);
          padding: 3.2rem side();
        }
        &.active{
          li{
            animation: showMenuList 600ms ease-in-out forwards;
            @for $i from 1 through 10{
              &:nth-child(#{$i}){
                animation-delay: $i * 40ms + 400ms;
              }
            }
            @keyframes showMenuList {
              0%{
                transform: translateY(2.4rem);
                opacity: 0;
              }
              100%{
                transform: translateY(0);
                opacity: 1;
              }
            }
          }
        }
        li{
          line-height: 1;
          opacity: 0;
          a{
            font-size: 3.4vmin;
            letter-spacing: .12rem;
            line-height: 1;
            color: $white;
            fill: $white;
            display: inline-block;
            position: relative;
            padding: .4rem;
            z-index: 1;
            @media screen and (max-width: $phone){
              font-size: 4.2vmin;
            }
            &:before{
              content: attr(data-eng);
              display: block;
              font-size: 1.8vmin;
              text-transform: uppercase;
              margin: 0 0 .8rem;
              letter-spacing: .08;
              @media screen and (max-width: $phone){
                font-size: 3.2vmin;
                letter-spacing: .012vmin;
                margin: 0 0 .64rem;
              }
            }
            &:after{
              content: '';
              width: 0;
              height: 100%;
              background: $sub;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              transition: width 320ms $ease;
              z-index: -1;
            }
            &:hover{
              &:after{
                width: 100%;
                left: 0;
                right: auto;
              }
            }
            svg{
              width: 3.4vmin;
              height: 3.4vmin;
              display: inline-block;
              margin: 0 0 0 .8vmin;
              transform: translateY(.33vmin);
              @media screen and (max-width: $phone){
                width: 5vmin;
                height: 5vmin;
                transform: translateY(25%);
              }
            }
          }
        }
      }
    }
    .meta{
      width: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      background: darken($base, 4%);
      color: $white;
      fill: $white;
      padding: 1.6rem side();
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 2rem;
      @media screen and (max-width: $phone){
        width: 100vw;
        position: relative;
        top: 0;
        left: 0;
        right: auto;
        bottom: auto;
        align-self: flex-end;
        grid-template-columns: 1fr;
        gap: 1rem;
      }
      > *{
        align-self: center;
        @media screen and (max-width: $phone){
          justify-self: center;
        }
      }
      dt{
        font-size: .88rem;
        line-height: 1.6;
        text-align: justify;
        @media screen and (max-width: $phone){
          font-size: .96rem;
        }
      }
      dd{
        font-size: 2.24rem;
        line-height: 1;
        @include flex();
        justify-content: flex-start;
        align-items: center;
        gap: .64rem;
        svg{
          width: 2.32rem;
          height: 2.32rem;
        }
      }
    }
  }
}
</style>

<script>
export default {
  data: function(){
    return {
      trg: false
    }
  }
}
</script>
