// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz@0,6..96;1,6..96&family=Noto+Serif+JP&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,700;1,6..96,700&family=Noto+Serif+JP:wght@600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-20cbca03]:root {
  font-size: clamp(13px + 2 * (100vw - 1440px) / 480, 1vw, 16px + 2 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-20cbca03]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1119px) {
[data-v-20cbca03]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-20cbca03]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
.group[data-v-20cbca03] {
  padding: 2.4rem calc(var(--size) + 2vw);
  position: relative;
}
.group[data-v-20cbca03]:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2f2c2c;
  opacity: 0.04;
}
.group div[data-v-20cbca03] {
  max-width: 33rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2rem;
  position: relative;
  z-index: 2;
}
.group div[data-v-20cbca03] > * {
  align-self: center;
}
.group ul[data-v-20cbca03] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}
.group ul li a[data-v-20cbca03] {
  display: block;
}
.group ul li img[data-v-20cbca03] {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./group.vue","webpack://./src/js/components/group.vue"],"names":[],"mappings":"AAGA;6DAAA;AAeA;6DAAA;AAOA;6DAAA;AAEA;EACC,2FAAA;EAKA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;ACtBF;ADuBC;AAZD;IAaE,eAAA;ACpBA;AACF;ADqBC;AAfD;IAgBE,eAAA;IACE,YAAA;AClBF;AACF;ADmBC;AAnBD;IAoBE,iBAAA;IACE,YAAA;AChBF;AACF;ADmHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;AChHD;AC9DA;EACE,uCAAA;EACA,kBAAA;ADgEF;AC/DE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,mBFPK;EEQL,aAAA;ADiEJ;AC/DE;EACE,gBAAA;EACA,cAAA;EACA,aAAA;EACA,+BAAA;EACA,qBAAA;EACA,kBAAA;EACA,UAAA;ADiEJ;AChEI;EACE,kBAAA;ADkEN;AC/DE;EACE,aAAA;EACA,qCAAA;EACA,cAAA;ADiEJ;AC/DM;EACE,cAAA;ADiER;AC/DM;EACE,WAAA;ADiER","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
