import headers from './components/headers.vue'
import footers from './components/footers.vue'
import hero from './components/hero.vue'
import breadcrumb from './components/breadcrumb.vue'
import group from './components/group.vue'
import faq from './components/faq.vue'
import loading from './components/loading.vue'
import swiper from './components/swiper.vue'
import slice from './components/slice.vue'
import { createApp } from 'vue'

export default function partials(){
  return new Promise((resolve) => {
    const app = createApp({
      components: {
        headers: headers,
        footers: footers,
        hero: hero,
        breadcrumb: breadcrumb,
        swiper: swiper,
        group: group,
        faq: faq,
        loading: loading,
        slice: slice,
      }
    });
    
    // Vue.jsを#appにマウント
    app.mount('#app');
    resolve();
  });
}
