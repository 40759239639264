// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz@0,6..96;1,6..96&family=Noto+Serif+JP&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,700;1,6..96,700&family=Noto+Serif+JP:wght@600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-20e1a340]:root {
  font-size: clamp(13px + 2 * (100vw - 1440px) / 480, 1vw, 16px + 2 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-20e1a340]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1119px) {
[data-v-20e1a340]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-20e1a340]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
.load-enter-active[data-v-20e1a340],
.load-leave-active[data-v-20e1a340] {
  transition: all 1.6s 1s cubic-bezier(0.65, 0, 0.35, 1);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.load-enter-from[data-v-20e1a340],
.load-leave-to[data-v-20e1a340] {
  clip-path: polygon(-50% 0, -50% 0, 0 100%, 0 100%);
}
#loading[data-v-20e1a340] {
  width: 100%;
  height: 100%;
  background: rgb(20.6593406593, 19.3406593407, 19.3406593407);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
#loading .svg[data-v-20e1a340] {
  width: 14rem;
  margin: 0 0 4rem;
}
#loading .svg svg[data-v-20e1a340] {
  width: 100%;
  display: block;
}
#loading .svg .cls-1[data-v-20e1a340] {
  fill: #363581;
}
#loading .svg .cls-2[data-v-20e1a340] {
  fill: #fefcfc;
}
#loading .loadmeta[data-v-20e1a340] {
  width: 100%;
  padding: 1.6rem 0;
  position: absolute;
  left: 0;
  bottom: 0;
}
#loading #loadbar[data-v-20e1a340] {
  width: 80%;
  left: 10%;
  height: 1px;
  background: rgba(254, 252, 252, 0.08);
  position: relative;
}
#loading #loadbar .bar[data-v-20e1a340] {
  width: 0%;
  height: 100%;
  background: #363581;
  top: 0;
  left: 0;
  position: absolute;
  transition: all 400ms cubic-bezier(0.65, 0, 0.35, 1);
  transition-property: width opacity;
}
#loading #loadtxt[data-v-20e1a340] {
  color: #fefcfc;
  font-size: 0.72rem;
  text-align: center;
  text-transform: uppercase;
  margin: 1.6rem 0 0;
}
#loading #loadtxt span[data-v-20e1a340] {
  display: inline-block;
  margin: 0 0.4rem 0 0;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  animation: loadTxt-20e1a340 800ms cubic-bezier(0.65, 0, 0.35, 1) alternate infinite;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(1) {
  animation-delay: 50ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(2) {
  animation-delay: 100ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(3) {
  animation-delay: 150ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(4) {
  animation-delay: 200ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(5) {
  animation-delay: 250ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(6) {
  animation-delay: 300ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(7) {
  animation-delay: 350ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(8) {
  animation-delay: 400ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(9) {
  animation-delay: 450ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(10) {
  animation-delay: 500ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(11) {
  animation-delay: 550ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(12) {
  animation-delay: 600ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(13) {
  animation-delay: 650ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(14) {
  animation-delay: 700ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(15) {
  animation-delay: 750ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(16) {
  animation-delay: 800ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(17) {
  animation-delay: 850ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(18) {
  animation-delay: 900ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(19) {
  animation-delay: 950ms;
}
#loading #loadtxt span[data-v-20e1a340]:nth-of-type(20) {
  animation-delay: 1000ms;
}
@keyframes loadTxt-20e1a340 {
0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
}
100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
}
#loading #loadtxt span[data-v-20e1a340]:last-of-type {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./loading.vue","webpack://./src/js/components/loading.vue"],"names":[],"mappings":"AAGA;6DAAA;AAeA;6DAAA;AAOA;6DAAA;AAEA;EACC,2FAAA;EAKA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;ACtBF;ADuBC;AAZD;IAaE,eAAA;ACpBA;AACF;ADqBC;AAfD;IAgBE,eAAA;IACE,YAAA;AClBF;AACF;ADmBC;AAnBD;IAoBE,iBAAA;IACE,YAAA;AChBF;AACF;ADmHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;AChHD;AC9DA;;EAEE,sDAAA;EACA,kDAAA;ADgEF;AC9DA;;EAEE,kDAAA;ADiEF;AC9DA;EACE,WAAA;EACA,YAAA;EACA,4DAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EFkCD,aAAA;EACA,mBAAA;EACA,8BAHiC;EAIhC,iBAAA;EEnCA,mBAAA;EACA,uBAAA;ADoEF;ACnEE;EACE,YAAA;EACA,gBAAA;ADqEJ;ACpEI;EACE,WAAA;EACA,cAAA;ADsEN;ACpEI;EAAO,aAAA;ADuEX;ACvEyB;EAAO,aFtBxB;ACgGR;ACxEE;EACE,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,OAAA;EACA,SAAA;AD0EJ;ACxEE;EACE,UAAA;EACA,SAAA;EACA,WAAA;EACA,qCAAA;EACA,kBAAA;AD0EJ;ACzEI;EACE,SAAA;EACA,YAAA;EACA,mBAAA;EACA,MAAA;EACA,OAAA;EACA,kBAAA;EACA,oDAAA;EACA,kCAAA;AD2EN;ACxEE;EACE,cFjDI;EEkDJ,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;AD0EJ;ACzEI;EACE,qBAAA;EACA,oBAAA;EACA,wDAAA;EACA,mFAAA;AD2EN;ACzEQ;EACE,qBAAA;AD2EV;AC5EQ;EACE,sBAAA;AD8EV;AC/EQ;EACE,sBAAA;ADiFV;AClFQ;EACE,sBAAA;ADoFV;ACrFQ;EACE,sBAAA;ADuFV;ACxFQ;EACE,sBAAA;AD0FV;AC3FQ;EACE,sBAAA;AD6FV;AC9FQ;EACE,sBAAA;ADgGV;ACjGQ;EACE,sBAAA;ADmGV;ACpGQ;EACE,sBAAA;ADsGV;ACvGQ;EACE,sBAAA;ADyGV;AC1GQ;EACE,sBAAA;AD4GV;AC7GQ;EACE,sBAAA;AD+GV;AChHQ;EACE,sBAAA;ADkHV;ACnHQ;EACE,sBAAA;ADqHV;ACtHQ;EACE,sBAAA;ADwHV;ACzHQ;EACE,sBAAA;AD2HV;AC5HQ;EACE,sBAAA;AD8HV;AC/HQ;EACE,sBAAA;ADiIV;AClIQ;EACE,uBAAA;ADoIV;ACjIM;AACE;IACE,wDAAA;ADmIR;ACjIM;IACE,kDAAA;ADmIR;AACF;ACjIM;EACE,SAAA;ADmIR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
