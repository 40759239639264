// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz@0,6..96;1,6..96&family=Noto+Serif+JP&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,700;1,6..96,700&family=Noto+Serif+JP:wght@600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-8f55d7a2]:root {
  font-size: clamp(13px + 2 * (100vw - 1440px) / 480, 1vw, 16px + 2 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-8f55d7a2]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1119px) {
[data-v-8f55d7a2]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-8f55d7a2]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
nav[data-v-8f55d7a2] {
  position: absolute;
  top: 0;
  left: 0;
}
nav ol li[data-v-8f55d7a2] {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  line-height: 1rem;
  white-space: nowrap;
  -moz-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  -webkit-font-feature-settings: "normal";
  font-feature-settings: "normal";
}
nav ol li a[data-v-8f55d7a2], nav ol li span[data-v-8f55d7a2] {
  display: block;
}
@media screen and (max-width: 769px) {
nav ol li:first-child span[data-v-8f55d7a2] {
    display: none;
}
}
nav ol li:first-child svg[data-v-8f55d7a2] {
  fill: #541212;
  margin: 0 0 0.4rem;
}
@media screen and (max-width: 769px) {
nav ol li:first-child svg[data-v-8f55d7a2] {
    margin: 0;
}
}
nav ol li svg[data-v-8f55d7a2] {
  display: block;
  margin: 0.4rem auto;
  width: 1rem;
  transform: translateX(0.1rem);
}
@-moz-document url-prefix() {
nav ol li svg[data-v-8f55d7a2] {
    transform: translateX(-0.1rem);
}
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./breadcrumb.vue","webpack://./src/js/components/breadcrumb.vue"],"names":[],"mappings":"AAGA;6DAAA;AAeA;6DAAA;AAOA;6DAAA;AAEA;EACC,2FAAA;EAKA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;ACtBF;ADuBC;AAZD;IAaE,eAAA;ACpBA;AACF;ADqBC;AAfD;IAgBE,eAAA;IACE,YAAA;AClBF;AACF;ADmBC;AAnBD;IAoBE,iBAAA;IACE,YAAA;AChBF;AACF;ADmHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;AChHD;AC9DA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;ADgEF;AC9DI;EF8CH,aAAA;EACA,mBAAA;EACA,8BAHiC;EAIhC,iBAAA;EE/CI,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,8BAAA;EACA,yBAAA;EACA,uCAAA;EACA,+BAAA;ADmEN;AClEM;EACE,cAAA;ADoER;ACjEQ;AACE;IACE,aAAA;ADmEV;AACF;ACjEQ;EACE,aFhBH;EEiBG,kBAAA;ADmEV;AClEU;AAHF;IAII,SAAA;ADqEV;AACF;AClEM;EACE,cAAA;EACA,mBAAA;EACA,WAAA;EACA,6BAAA;ADoER;ACnEQ;AALF;IAMI,8BAAA;ADsER;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
