// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz@0,6..96;1,6..96&family=Noto+Serif+JP&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,700;1,6..96,700&family=Noto+Serif+JP:wght@600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-3d6d661a]:root {
  font-size: clamp(13px + 2 * (100vw - 1440px) / 480, 1vw, 16px + 2 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-3d6d661a]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1119px) {
[data-v-3d6d661a]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-3d6d661a]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
.trg-enter-active[data-v-3d6d661a],
.trg-leave-active[data-v-3d6d661a] {
  transition: all 0.2s cubic-bezier(0.65, 0, 0.35, 1);
}
.trg-enter-from[data-v-3d6d661a],
.trg-leave-to[data-v-3d6d661a] {
  opacity: 0;
}
dl[data-v-3d6d661a] {
  background: #fefcfc;
}
dl dt[data-v-3d6d661a], dl dd[data-v-3d6d661a] {
  padding: 1.6rem 2rem;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
}
@media screen and (max-width: 769px) {
dl dt[data-v-3d6d661a], dl dd[data-v-3d6d661a] {
    padding: 1.6rem;
}
}
dl dt[data-v-3d6d661a]:before, dl dd[data-v-3d6d661a]:before {
  font-size: 1.4rem;
  font-weight: 700;
  display: block;
  align-self: flex-start;
}
@media screen and (max-width: 769px) {
dl dt[data-v-3d6d661a]:before, dl dd[data-v-3d6d661a]:before {
    font-size: 1rem;
}
}
dl dt[data-v-3d6d661a] {
  font-size: 1.4rem;
  font-weight: 700;
  padding-right: 4rem;
  position: relative;
  cursor: pointer;
  user-select: none;
}
@media screen and (max-width: 769px) {
dl dt[data-v-3d6d661a] {
    font-size: 1rem;
    padding-right: 3.2rem;
}
}
dl dt.active svg[data-v-3d6d661a] {
  transform: translateY(-50%) rotate(180deg);
}
dl dt svg[data-v-3d6d661a] {
  width: 2rem;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  fill: #541212;
  transition: transform 200ms cubic-bezier(0.65, 0, 0.35, 1);
}
@media screen and (max-width: 769px) {
dl dt svg[data-v-3d6d661a] {
    width: 1.6rem;
}
}
dl dt[data-v-3d6d661a]:before {
  content: "Q";
  color: #541212;
}
dl dd[data-v-3d6d661a] {
  font-size: 1.08rem;
  border-top: rgb(236.5115384615, 231.6884615385, 231.6884615385) 1px solid;
}
@media screen and (max-width: 769px) {
dl dd[data-v-3d6d661a] {
    font-size: 0.92rem;
}
}
dl dd[data-v-3d6d661a]:before {
  content: "A";
  color: #8b6509;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./faq.vue","webpack://./src/js/components/faq.vue"],"names":[],"mappings":"AAGA;6DAAA;AAeA;6DAAA;AAOA;6DAAA;AAEA;EACC,2FAAA;EAKA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;ACtBF;ADuBC;AAZD;IAaE,eAAA;ACpBA;AACF;ADqBC;AAfD;IAgBE,eAAA;IACE,YAAA;AClBF;AACF;ADmBC;AAnBD;IAoBE,iBAAA;IACE,YAAA;AChBF;AACF;ADmHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;AChHD;AC9DA;;EAEE,mDAAA;ADgEF;AC9DA;;EAEE,UAAA;ADiEF;AC9DA;EACE,mBFJM;ACqER;AChEE;EACE,oBAAA;EACA,aAAA;EACA,+BAAA;EACA,SAAA;ADkEJ;ACjEI;AALF;IAMI,eAAA;ADoEJ;AACF;ACnEI;EACE,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,sBAAA;ADqEN;ACpEM;AALF;IAMI,eAAA;ADuEN;AACF;ACpEE;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;ADsEJ;ACrEI;AAPF;IAQI,eAAA;IACA,qBAAA;ADwEJ;AACF;ACtEM;EACE,0CAAA;ADwER;ACrEI;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;EACA,2BAAA;EACA,aFzCC;EE0CD,0DAAA;ADuEN;ACtEM;AARF;IASI,aAAA;ADyEN;AACF;ACvEI;EACE,YAAA;EACA,cFjDC;AC0HP;ACtEE;EACE,kBAAA;EACA,yEAAA;ADwEJ;ACvEI;AAHF;IAII,kBAAA;AD0EJ;AACF;ACzEI;EACE,YAAA;EACA,cF3DA;ACsIN","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
