<template>
  <section class="in-module bg-tex group">
    <div>
      <h3>Other Group</h3>
      <ul>
        <li><a href="" target="_blank" rel="nofollow"><img src="/images/common/logo_rokuza.webp" alt="rokuza"></a></li>
        <li><a href="" target="_blank" rel="nofollow"><img src="/images/common/logo_marche.webp" alt="海のマルシェたてやま"></a></li>
        <li><a href="" target="_blank" rel="nofollow"><img src="/images/common/logo_solennel.webp" alt="solennel"></a></li>
        <li><a href="" target="_blank" rel="nofollow"><img src="/images/common/logo_nagisa.webp" alt="なぎさ食堂"></a></li>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.group{
  padding: 2.4rem side();
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $normal;
    opacity: .04;
  }
  div{
    max-width: 33rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 2rem;
    position: relative;
    z-index: 2;
    > *{
      align-self: center;
    }
  }
  ul{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    li{
      a{
        display: block;
      }
      img{
        width: 100%;
      }
    }
  }
}
</style>