<template>
	<footer id="site-footer" class="in-module" role="contentinfo" itemscope="itemscope" itemtype="http://schema.org/WPFooter">
    <a href="#app" class="pagetop">▲ ページの先頭へ</a>
    <div class="max">
      <div class="company">
        <figure><img src="/images/common/logo_w.webp" alt="ろくや"></figure>
        <dl>
          <dt>南房総 岩井湯元温泉 網元の宿 ろくや</dt>
          <dd>〒299-2216 千葉県南房総市久枝494<br>TEL.0470-57-2011 / FAX.0470-57-2501</dd>
          <dd>
            <ul class="sns">
              <li><a href="" area-label="FaceBook"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z"/></svg></a></li>
              <li><a href="" area-label="Instagram"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path d="M16,3H8C5.239,3,3,5.239,3,8v8c0,2.761,2.239,5,5,5h8c2.761,0,5-2.239,5-5V8C21,5.239,18.761,3,16,3z M12,17c-2.761,0-5-2.239-5-5s2.239-5,5-5s5,2.239,5,5S14.761,17,12,17z M18,7c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S18.552,7,18,7z"/><circle cx="12" cy="12" r="3"/></svg></a></li>
            </ul>
          </dd>
        </dl>
      </div>
      <nav>
        <ul>
          <li itemprop="name"><a itemprop="url" href="/" data-eng="Rokuya">インデックス</a></li>
          <li itemprop="name"><a itemprop="url" href="/aboutus/" data-eng="Rokuya">ろくやについて</a></li>
          <li itemprop="name"><a itemprop="url" href="/plan/" data-eng="Plan">宿泊プラン</a></li>
          <li itemprop="name"><a itemprop="url" href="/material/" data-eng="ingredients">厳選素材</a></li>
          <li itemprop="name"><a itemprop="url" href="/room/" data-eng="Room">お部屋</a></li>
          <li itemprop="name"><a itemprop="url" href="/spa/" data-eng="Hot spring">温泉</a></li>
          <li itemprop="name"><a itemprop="url" href="/access/" data-eng="Access">交通・観光</a></li>
          <li itemprop="name"><a itemprop="url" href="/faq/" data-eng="Q&amp;A">よくある質問</a></li>
          <li itemprop="name"><a data-eng="Reservation" href="https://reserve.489ban.net/client/rokuya-resort/0/plan/availability/daily" target="_blank" rel="nofollow">ご予約</a></li>
        </ul>
      </nav>
    </div>
    <small class="copy">Copyright &copy; {{ currentYear }} Rokuya All rights reserved.</small>
	</footer>
</template>

<style lang="scss" scoped>
footer{
  background: $normal;
  color: darken($gray, 20%);
  a{
    color: darken($gray, 20%);
  }
  .pagetop{
    background: lighten($normal, 4%);
    display: block;
    text-align: center;
    padding: 1rem 0;
  }
  .max{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: side2x();
    padding: 4rem side();
    @media screen and (max-width: $tab){
      grid-template-columns: 1fr;
    }
  }
  .company{
    display: grid;
    grid-template-columns: 10.6rem 1fr;
    grid-gap: 2rem;
    @media screen and (max-width: $tab){
      justify-self: center;
    }
    @media screen and (max-width: $phone){
      grid-template-columns: 7.2rem 1fr;
    }
    figure{
      img{
        opacity: .8;
        width: 100%;
        object-fit: cover;
      }
    }
    dl{
      line-height: 1.56;
      @media screen and (max-width: $phone){
        font-size: .92rem;
      }
      dd{
        font-size: .72rem;
        margin: .4rem 0 0;
      }
    }
    .sns{
      padding: .8rem 0 0;
      @include flex();
      align-items: center;
      justify-content: flex-start;
      li{
        margin: 0 .64rem 0 0;
        &:last-child{
          margin: 0;
        }
        a{
          width: 2rem;
          height: 2rem;
          border: rgba($gray,.64) .1rem solid;
          background: rgba($white,0);
          @include flex();
          align-items: center;
          justify-content: center;
          border-radius: .4rem;
          position: relative;
          transition: all 320ms $ease;
          transition-property: border, transform;
          &:hover{
            border-color: $white;
            background: rgba($white,1);
            svg{
              fill: $base;
            }
          }
          svg{
            width: .88rem;
            height: .88rem;
            fill: rgba($gray,.9);
            position: relative;
            z-index: 2;
          }
        }
      }
    }
  }
  nav{
    ul{
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 1rem;
      grid-row-gap: .4rem;
      @media screen and (max-width: $tab){
        grid-template-columns: repeat(5, 1fr);
      }
      @media screen and (max-width: $phone){
        grid-template-columns: repeat(2, 1fr);
        gap: 1px;
        text-align: center;
      }
      li{
        @media screen and (max-width: $phone){
          &:first-of-type{
            display: none;
          }
        }
        a{
          font-size: .92rem;
          @media screen and (max-width: $phone){
            background: lighten($normal, 4%);
            display: block;
            padding: 1.2rem side();
          }
          &:hover{
            text-decoration: underline;
            @media screen and (max-width: $phone){
              text-decoration: none;
              background: lighten($normal, 6%);
            }
          }
        }
      }
    }
  }
  small{
    display: block;
    text-align: center;
    font-size: .72rem;
    border-top: rgba($white,.04) .1rem solid;
    padding: 1.6rem 0;
  }
}
</style>

<script>
export default {
	data() {
		return {
			currentYear: new Date().getFullYear()
		}
	}
}
</script>