import * as imagesLoaded from 'imagesloaded'
import GsapSmoothScroll from './class/GsapSmoothScroll'
import gsap from 'gsap'
import { ScrollTrigger , ScrollToPlugin } from 'gsap/all'
import common from './common'
import index from './index'
import partials from './partials'

gsap.registerPlugin(ScrollToPlugin)

let smoother
document.addEventListener('DOMContentLoaded', function() {
  imagesLoaded(document.getElementById('app'), () => {
    partials().then(() => {
      setTimeout(()=>{
        //smoother = new GsapSmoothScroll("#scrollArea", null, 1.6)
        
        common()
        index()
        setProp()
        initContactForm()
        smoothScrollToTargetIfHashExists()
        require("fslightbox")
      },100)
    })
  })
})

function setProp() {
  if (smoother != null) {
    document.documentElement.style.setProperty('--screen', `${smoother.getOffsets()}px`)
  } else {
    cancelAnimationFrame(setProp)
  }
  requestAnimationFrame(setProp)
}

function initContactForm() {
  setTimeout(()=>{
    const cf_selector = 'div.wpcf7 > form'
    const cf_forms = document.querySelectorAll(cf_selector)
    
    if (cf_forms.length > 0) {
      const cf_load = document.querySelector('.wpcf7-spinner')
      cf_load.remove()
      window.wpcf7.init(cf_forms[0])
    }
  },1000)
}

function smoothScrollToTargetIfHashExists() {
  const hash = window.location.hash;
  
  if (hash && hash !== "#") {
    const targetElement = document.getElementById(hash.split("#")[1]);
    if (targetElement) {
      scrollToAnimated(targetElement)
    }
  }
}

function scrollToAnimated(targetElement) {
  const headerHeight = 32 //document.getElementById('site-header').offsetHeight;
  const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - headerHeight;
  gsap.to(window, { duration: 0.8, scrollTo: targetPosition });
}

