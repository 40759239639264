<template>
  <dl>
    <dt @click="trg = !trg" :class="{'active': trg}"><span v-html="title"></span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480-371.69 267.69-584 296-612.31l184 184 184-184L692.31-584 480-371.69Z"/></svg></dt>
    <Transition name="trg">
      <dd v-if="trg" v-html="texts"></dd>
    </Transition>
  </dl>
</template>

<style lang="scss" scoped>
.trg-enter-active,
.trg-leave-active {
  transition: all .2s $ease;
}
.trg-enter-from,
.trg-leave-to {
  opacity: 0;
}

dl{
  background: $white;
  dt,dd{
    padding: 1.6rem 2rem;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
    @media screen and (max-width: $phone){
      padding: 1.6rem;
    }
    &:before{
      font-size: 1.4rem;
      font-weight: 700;
      display: block;
      align-self: flex-start;
      @media screen and (max-width: $phone){
        font-size: 1rem;
      }
    }
  }
  dt{
    font-size: 1.4rem;
    font-weight: 700;
    padding-right: 4rem;
    position: relative;
    cursor: pointer;
    user-select: none;
    @media screen and (max-width: $phone){
      font-size: 1rem;
      padding-right: 3.2rem;
    }
    &.active{
      svg{
        transform: translateY(-50%) rotate(180deg);
      }
    }
    svg{
      width: 2rem;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      fill: $base;
      transition: transform 200ms $ease;
      @media screen and (max-width: $phone){
        width: 1.6rem;
      }
    }
    &:before{
      content: 'Q';
      color: $base;
    }
  }
  dd{
    font-size: 1.08rem;
    border-top: lighten($border, 2%) 1px solid;
    @media screen and (max-width: $phone){
      font-size: .92rem;
    }
    &:before{
      content: 'A';
      color: $sub;
    }
  }
}
</style>

<script>
import { ref } from 'vue';

export default{
  props: {
    title: {
      type: String,
      required: true
    },
    texts: {
      type: String,
      required: true
    },
  },
  setup(props){
    const trg = ref(false)
    return {
      trg,
      title: props.title,
      texts: props.texts
    }
  },
}
</script>