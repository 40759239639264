<template>
  <nav>
    <ol itemscope itemtype="https://schema.org/BreadcrumbList">
      <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M180-140v-450l300-225.77L780-590v450H556.15v-267.69h-152.3V-140H180Z"/></svg>
        <a to="/" itemprop="item"><span itemprop="name">岩井湯本温泉 ろくや</span></a>
        <meta itemprop="position" content="1">
      </li>
      <li v-for="(item, index) in list" :key="index" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480-371.69 267.69-584 296-612.31l184 184 184-184L692.31-584 480-371.69Z"/></svg>
        <a :href="item.link" itemprop="item" v-if="item.link"><span itemprop="name">{{ item.title }}</span></a>
        <span itemprop="item" v-else><span itemprop="name">{{ item.title }}</span></span>
        <meta itemprop="position" :content="index + 2">
      </li>
    </ol>
  </nav>
</template>

<style lang="scss" scoped>
nav{
  position: absolute;
  top: 0;
  left: 0;
  ol{
    li{
      @include flex();
      align-items: center;
      justify-content: center;
      font-size: .8rem;
      line-height: 1rem;
      white-space: nowrap;
      -moz-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
      -webkit-font-feature-settings : "normal";
      font-feature-settings : "normal";
      a,span{
        display: block;
      }
      &:first-child{
        @media screen and (max-width: $phone){
          span{
            display: none;
          }
        }
        svg{
          fill: $base;
          margin: 0 0 .4rem;
          @media screen and (max-width: $phone){
            margin: 0;
          }
        }
      }
      svg{
        display: block;
        margin: .4rem auto;
        width: 1rem;
        transform: translateX(.1rem);
        @-moz-document url-prefix() {
          transform: translateX(-.1rem);
        }
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
  }
}
</script>
