// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz@0,6..96;1,6..96&family=Noto+Serif+JP&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,700;1,6..96,700&family=Noto+Serif+JP:wght@600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-7139e23c]:root {
  font-size: clamp(13px + 2 * (100vw - 1440px) / 480, 1vw, 16px + 2 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-7139e23c]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1119px) {
[data-v-7139e23c]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-7139e23c]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
footer[data-v-7139e23c] {
  background: #2f2c2c;
  color: rgb(200.1, 187.9, 187.9);
}
footer a[data-v-7139e23c] {
  color: rgb(200.1, 187.9, 187.9);
}
footer .pagetop[data-v-7139e23c] {
  background: rgb(57.5362637363, 53.8637362637, 53.8637362637);
  display: block;
  text-align: center;
  padding: 1rem 0;
}
footer .max[data-v-7139e23c] {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: calc(calc(var(--size) + 2vw) * 1.5);
  padding: 4rem calc(var(--size) + 2vw);
}
@media screen and (max-width: 1119px) {
footer .max[data-v-7139e23c] {
    grid-template-columns: 1fr;
}
}
footer .company[data-v-7139e23c] {
  display: grid;
  grid-template-columns: 10.6rem 1fr;
  grid-gap: 2rem;
}
@media screen and (max-width: 1119px) {
footer .company[data-v-7139e23c] {
    justify-self: center;
}
}
@media screen and (max-width: 769px) {
footer .company[data-v-7139e23c] {
    grid-template-columns: 7.2rem 1fr;
}
}
footer .company figure img[data-v-7139e23c] {
  opacity: 0.8;
  width: 100%;
  object-fit: cover;
}
footer .company dl[data-v-7139e23c] {
  line-height: 1.56;
}
@media screen and (max-width: 769px) {
footer .company dl[data-v-7139e23c] {
    font-size: 0.92rem;
}
}
footer .company dl dd[data-v-7139e23c] {
  font-size: 0.72rem;
  margin: 0.4rem 0 0;
}
footer .company .sns[data-v-7139e23c] {
  padding: 0.8rem 0 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
footer .company .sns li[data-v-7139e23c] {
  margin: 0 0.64rem 0 0;
}
footer .company .sns li[data-v-7139e23c]:last-child {
  margin: 0;
}
footer .company .sns li a[data-v-7139e23c] {
  width: 2rem;
  height: 2rem;
  border: rgba(246, 244, 244, 0.64) 0.1rem solid;
  background: rgba(254, 252, 252, 0);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  position: relative;
  transition: all 320ms cubic-bezier(0.65, 0, 0.35, 1);
  transition-property: border, transform;
}
footer .company .sns li a[data-v-7139e23c]:hover {
  border-color: #fefcfc;
  background: #fefcfc;
}
footer .company .sns li a:hover svg[data-v-7139e23c] {
  fill: #541212;
}
footer .company .sns li a svg[data-v-7139e23c] {
  width: 0.88rem;
  height: 0.88rem;
  fill: rgba(246, 244, 244, 0.9);
  position: relative;
  z-index: 2;
}
footer nav ul[data-v-7139e23c] {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 0.4rem;
}
@media screen and (max-width: 1119px) {
footer nav ul[data-v-7139e23c] {
    grid-template-columns: repeat(5, 1fr);
}
}
@media screen and (max-width: 769px) {
footer nav ul[data-v-7139e23c] {
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    text-align: center;
}
}
@media screen and (max-width: 769px) {
footer nav ul li[data-v-7139e23c]:first-of-type {
    display: none;
}
}
footer nav ul li a[data-v-7139e23c] {
  font-size: 0.92rem;
}
@media screen and (max-width: 769px) {
footer nav ul li a[data-v-7139e23c] {
    background: rgb(57.5362637363, 53.8637362637, 53.8637362637);
    display: block;
    padding: 1.2rem calc(var(--size) + 2vw);
}
}
footer nav ul li a[data-v-7139e23c]:hover {
  text-decoration: underline;
}
@media screen and (max-width: 769px) {
footer nav ul li a[data-v-7139e23c]:hover {
    text-decoration: none;
    background: rgb(62.8043956044, 58.7956043956, 58.7956043956);
}
}
footer small[data-v-7139e23c] {
  display: block;
  text-align: center;
  font-size: 0.72rem;
  border-top: rgba(254, 252, 252, 0.04) 0.1rem solid;
  padding: 1.6rem 0;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./footers.vue","webpack://./src/js/components/footers.vue"],"names":[],"mappings":"AAGA;6DAAA;AAeA;6DAAA;AAOA;6DAAA;AAEA;EACC,2FAAA;EAKA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;ACtBF;ADuBC;AAZD;IAaE,eAAA;ACpBA;AACF;ADqBC;AAfD;IAgBE,eAAA;IACE,YAAA;AClBF;AACF;ADmBC;AAnBD;IAoBE,iBAAA;IACE,YAAA;AChBF;AACF;ADmHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AChHD;ADkHA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;AChHD;AC9DA;EACE,mBFEO;EEDP,+BAAA;ADgEF;AC/DE;EACE,+BAAA;ADiEJ;AC/DE;EACE,4DAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;ADiEJ;AC/DE;EACE,aAAA;EACA,+BAAA;EACA,6CAAA;EACA,qCAAA;ADiEJ;AChEI;AALF;IAMI,0BAAA;ADmEJ;AACF;ACjEE;EACE,aAAA;EACA,kCAAA;EACA,cAAA;ADmEJ;AClEI;AAJF;IAKI,oBAAA;ADqEJ;AACF;ACpEI;AAPF;IAQI,iCAAA;ADuEJ;AACF;ACrEM;EACE,YAAA;EACA,WAAA;EACA,iBAAA;ADuER;ACpEI;EACE,iBAAA;ADsEN;ACrEM;AAFF;IAGI,kBAAA;ADwEN;AACF;ACvEM;EACE,kBAAA;EACA,kBAAA;ADyER;ACtEI;EACE,mBAAA;EFEL,aAAA;EACA,mBAAA;EACA,8BAHiC;EAIhC,iBAAA;EEHI,mBAAA;EACA,2BAAA;AD2EN;AC1EM;EACE,qBAAA;AD4ER;AC3EQ;EACE,SAAA;AD6EV;AC3EQ;EACE,WAAA;EACA,YAAA;EACA,8CAAA;EACA,kCAAA;EFXT,aAAA;EACA,mBAAA;EACA,8BAHiC;EAIhC,iBAAA;EEUQ,mBAAA;EACA,uBAAA;EACA,qBAAA;EACA,kBAAA;EACA,oDAAA;EACA,sCAAA;ADgFV;AC/EU;EACE,qBFjEJ;EEkEI,mBAAA;ADiFZ;AChFY;EACE,aFhEP;ACkJP;AC/EU;EACE,cAAA;EACA,eAAA;EACA,8BAAA;EACA,kBAAA;EACA,UAAA;ADiFZ;AC1EI;EACE,aAAA;EACA,qCAAA;EACA,qBAAA;EACA,oBAAA;AD4EN;AC3EM;AALF;IAMI,qCAAA;AD8EN;AACF;AC7EM;AARF;IASI,qCAAA;IACA,QAAA;IACA,kBAAA;ADgFN;AACF;AC9EQ;AACE;IACE,aAAA;ADgFV;AACF;AC9EQ;EACE,kBAAA;ADgFV;AC/EU;AAFF;IAGI,4DAAA;IACA,cAAA;IACA,uCAAA;ADkFV;AACF;ACjFU;EACE,0BAAA;ADmFZ;AClFY;AAFF;IAGI,qBAAA;IACA,4DAAA;ADqFZ;AACF;AC/EE;EACE,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,kDAAA;EACA,iBAAA;ADiFJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
