<template>
  <div>
    <template v-for="(char, index) in chars" :key="index">
      <span v-if="char === ' '" v-html="'&nbsp;'"></span>
      <span v-else-if="char !== '<br>'">{{ char }}</span>
      <br v-else />
    </template>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const chars = computed(() => {
      return props.text
        .replace(/<br\s*\/?>/gi, '<br>') // normalize <br> tags
        .split(/(<br>)/)                 // split text by <br>
        .map(part => (part === '<br>' ? '<br>' : part.split('')))
        .flat();                         // flatten nested arrays
    });

    return { chars };
  },
};
</script>

<style scoped lang="scss">
span {
  display: inline-block;
}
</style>
